nav{
    background: rgba(0, 0, 0, 0.6);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 1rem;
    backdrop-filter: blue(15px);
}
nav a{
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}
nav a:hover{
    background: rgba(0, 0, 0, 0.3);

}
nav a.active{
    background: var(--color-primary);
    color: var(--color-white);
}

@media screen and (max-width: 600px) {
    nav{
        bottom: 0;
        border-radius: 1rem;
    }
}
  
nav a,
.dropdown button {
  color: inherit;
  text-decoration: none;
  padding: 10px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  color: inherit;
}

.dropup-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 118%; /* Position it above the button */
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropup-content a {
  color: white;
  width: 40px;
  border-radius: 10px;
  padding: 10px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropup-content a:hover {
  background-color: #ddd;
}
.portfolio__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid: 2.5rem;
}
.portfolio__items{
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.portfolio__items:hover{
    border-color: var(--color-primary-variant);
    background: transparent;

}
.portfolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}
.portfolio__item-image img{
    height: 360px;
}
.portfolio__items h3{
    margin: 1.2rem 0 2rem;

}
.portfolio__item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;

}
.view_btn {
    display: flex;
    justify-content: center;
    align-items: end;
}


@media screen and (max-width: 1024px) {
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
    
}

@media screen and (max-width: 600px) {
    .portfolio__container{
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }
    
}

.sidebar {
  height: 100vh;
  background-color: #2d3748;
}


.right{
  background-color: #1a202c;
  color: #fff;
}

.popup-b {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-inner-b {
  background: #fff;
  color: #1a202c;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: relative;
}

.popup-close-b {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
}





.show-blog-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner-sb {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  height: 650px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-title {
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.blog-description {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #555;
}

.publish-date {
  font-size: 1em;
  color: #777;
  margin-bottom: 20px;
}

.comments-section {
  margin-top: 20px;
  height: 400px;
  overflow-y: auto;
}

.comment {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comment-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-avatar {
  width: 40px;
  height: 40px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  margin-right: 10px;
}

.comment-info p {
  margin: 0;
  font-size: 0.9em;
}

.comment-info strong {
  font-size: 1em;
  color: #333;
}

.toggle-replies-btn {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.toggle-replies-btn:hover {
  background-color: #0056b3;
}

.replies {
  margin-top: 10px;
  padding-left: 20px;
  border-left: 2px solid #ddd;
}

.reply {
  background-color: #f0f0f0;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
}

.error-message {
  text-align: center;
  color: red;
}

.btn-close-sb {
  background-color: #ff5c5c;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
}

.btn-close-sb:hover {
  background-color: #e03e3e;
}

h5 {
  margin-top: 10px;
  font-size: 1.1em;
  color: #333;
}

.blogTitle,
.blogDescription {
  color: #1a202c;
}
.btn-sm{
  color: #1a202c !important;
}
.btn-sm :hover{
  color: #1a202c;
}
.experince__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}
.experince__container > div{
    background: var(--color-primary-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.experince__container > div:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}
.experince__container > div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}
.experience__content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}
.experince__details{
    display: flex;
    gap: 1rem;
}
.experince__details-icon{
    margin-top: 6px;
    color: var(--color-primary);
}
.view_btn {
    margin-top: 4rem;
}
.about_experience {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 17px;
}
.exp-data{
    margin-top: -15px;
}
@media screen and (max-width: 1024px) {
   .experince__container{
    grid-template-columns: 1fr;
   }
   .experince__container > div{
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
   }
   .experience__content{
    padding: 1rem;
   }
   .experince__container{
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .experince__container{
        gap: 1rem;
    }
    .experince__container > div{
        width: 100%;
        padding: 2rem 1rem;
    }
    .experince__container{
        margin: 0 !important;
    }
    
    
}
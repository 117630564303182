
.portfolio__item_image {
  position: relative;
  overflow: hidden;
  width: 100%; 
  height: 540px; 
  border-radius: 2rem;
}

.overlay {
  position: fixed;
  top: 10px;
  left: 15%;
  width: 70%;
  height: 200%; /* Adjust as needed */
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.overlay img {
  max-width: 100%;
  max-height: 100%;
}

.portfolio__item-image.zoomed {
  cursor: pointer;
  z-index: 1000;
}

@media screen and (max-width: 1024px) {
  .portfolio__item_image {
    position: relative;
    overflow: hidden;
    width: 100%; 
    height: 512px; 
    border-radius: 2rem;
  }
  
}

@media screen and (max-width: 600px) {
  .portfolio__item_image {
    position: relative;
    overflow: hidden;
    width: 100%; 
    height: 370px; 
    border-radius: 2rem;
  }
  
}
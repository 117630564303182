header{
    height: 100vh;
    /* padding-top: 5rem; */
    overflow: hidden;
}
.header__container{
    display: flex;
    justify-content: space-between;
    /* text-align: center; */
    height: 100%;
    position: relative;
}
/* CTA */
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}
/* social */
.header__social{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}
.header__social::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}
/* me */
.container2 {
    margin-left: 15rem;
    margin-top: 7rem;
}
.me{
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(70% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}
.my_pic{
    margin-top: -50px;
    height: 530px;
}
/* scroll down */
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

.rsc-footer input{
    color: black;
}
.rsc-header .rsc-header-logo {
    display: none;
  }
  


/* media query */
@media screen and (max-width: 1024px){
    header{
        height: 68vh;

    }
}

/* @media screen and (max-width: 600px){
    header{
        height: 100vh;
        
    }
    .header__social, .scroll__down{
        display: none;
    }

} */

/* media query */
@media screen and (max-width: 600px) {
    header {
      height: 100vh;
      padding-top: 6rem;
    }
  
    .header__social,
    .scroll__down {
      display: none;
    }
  
    .header__container {
      flex-direction: column;
      align-items: center;
    }
  
    .container2 {
      order: 2; /* Set the order to 2 for container2 */
      margin-top: -4.5rem; /* Add margin-top for spacing */
      margin-right: 15rem;
    }
    .typer{
        padding: 0 !important;
    }
    .cta{
        margin-top: 10px;
    }
  
    .me {
        order: 1;
        margin-left: -4rem;
        margin-top: 20rem;
        position: absolute;
    }
    .my_pic{
        margin-top: -7rem;
    }
  }
  